.project {
  display: flex;
  padding-bottom: 40px;

  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }

  &__direction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-inline: 100px;

    @media only screen and (max-width: 1024px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-inline: 16px;
    }
  }

  &__inline {
    display: flex;
    justify-content: center;
    gap: 16px;

    @media only screen and (max-width: 530px) {
      flex-wrap: wrap;
    }
  }

  &__texts-ehilapp {
    @media only screen and (max-width: 1024px) {
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-inline: 24px;
      padding-bottom: 24px;
    }
  }

  &__subtitle {
    display: flex;
    flex-direction: column;
  }

  &__card {
    margin-bottom: 24px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

    @media only screen and (max-width: 600px) {
      padding: 16px;
    }
  }

  &__title-card {
    color: white;
  }

  &__subtitle-card {
    color: white;
  }

  &__card:hover {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    transform: scale(1.03);
  }

  &__desc-ehilapp {
    line-height: 25px;
    color: white;
  }
}
