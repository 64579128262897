@import "./styles/variables.scss";

body {
  margin: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
}

.white-color {
  color: white;
}

.blu-color {
  color: #036c9a;
}

.text-decoration {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
