.header {
  padding-block: 16px;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: black;
  box-shadow: none;
  transition: box-shadow 0.3s ease;

  &__name {
    color: white;
    font-weight: bold;
    letter-spacing: 0.3rem;
  }

  nav {
    display: flex;
    gap: 24px;

    @media only screen and (max-width: 720px) {
      display: none;
    }
  }

  &__container-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    top: 64px;
    right: 0;
    left: 0;
    background-color: #131313;
    // padding: 24px;
    margin-top: 16px;
    z-index: 999;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    max-height: 0; // Inizialmente chiuso
    overflow: hidden; // Nasconde il contenuto in eccesso
    transition: max-height 0.8s ease; // Aggiungi transizione

    &.open {
      max-height: 400px; // Valore grande per garantire che il contenuto venga visualizzato
    }
  }

  &__link {
    color: white;
    font-size: 16px;
    padding-block: 16px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  &__link-mobile {
    font-size: 16px;
    padding-block: 16px;
    padding-inline: 16px;
    color: white;
    text-decoration: none;

    &:hover {
      background-color: #282828;
    }
  }

  &__icon {
    display: none;
    @media only screen and (max-width: 720px) {
      display: block;
      cursor: pointer;
    }
  }
}

.header.scrolled {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Imposta il box-shadow che vuoi vedere quando scrolli
}

.me-auto {
  background-color: red;
}
