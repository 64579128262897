.homepage {
  &__img {
    height: 400px;

    border-radius: 50%;

    @media only screen and (max-width: 720px) {
      height: 300px;
    }
  }

  &__inline-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding-inline: 200px;
    padding-block: 48px;
    padding-top: 100px;

    font-family: Verdana, Geneva, Tahoma, sans-serif;

    @media only screen and (max-width: 1024px) {
      padding-inline: 100px;
    }

    @media only screen and (max-width: 750px) {
      flex-direction: column;
      padding-inline: 16px;
      padding-top: 40px;
    }
  }

  &__presentation {
    font-size: 24px;
    line-height: 40px;
    font-weight: 300;
    color: white;
    @media only screen and (max-width: 720px) {
      font-size: 16px;
    }
  }

  &__strong {
    color: white;
    font-weight: bold;
  }

  &__title {
    text-align: left;
    font-size: 32px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    padding-inline: 48px;
  }

  &__subtitle {
    // display: flex;
    text-align: center;
    // align-items: center;
    // justify-content: center;
    padding-inline: 200px;
    padding-block: 48px;
    line-height: 30px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    @media only screen and (max-width: 720px) {
      padding-inline: 16px;
    }
  }

  // ABOUT ME
  &__container-about-me {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__container-info {
    display: flex;
    align-items: center;
    gap: 32px;

    &__inline {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__key {
      font-weight: bold;
      color: white;
    }

    &__value {
      color: white;
    }
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media only screen and (max-width: 450px) {
      padding-inline: 16px;
    }
  }

  &__img-center {
    border-radius: 100px;
    height: 200px;
  }

  // SKILLS

  &__align-skills {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding-block: 48px;

    @media only screen and (max-width: 720px) {
      flex-wrap: wrap;
      padding-inline: 16px;
    }
  }
}
