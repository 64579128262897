.contact-form {
  background-color: white;
  margin-inline: 300px;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 72px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 1200px) {
    margin-inline: 24px;
    margin-bottom: 32px !important;
    padding: 16px;
  }

  &__title {
    margin-bottom: 32px;
    text-align: center;
    color: #333;
  }

  &__name-inline {
    display: flex;
    width: 100%;
    gap: 16px;

    @media only screen and (max-width: 1250px) {
      flex-direction: column;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media only screen and (max-width: 1250px) {
      gap: 16px;
    }
  }

  &__form-group {
    display: flex;
    flex: 1;
    flex-direction: column;

    label {
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: #555;
    }

    input,
    textarea {
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;
      &:hover {
        border-color: black;
      }
      &:focus-visible {
        border: 1px solid black !important;
      }
    }

    textarea {
      resize: vertical;
      height: 100px;
    }
  }

  &__submit-button {
    padding: 0.75rem;
    background: black;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background: #2f3032;
    }
  }

  .successMessage {
    margin-top: 1rem;
    color: green;
    text-align: center;
  }

  .errorMessage {
    margin-top: 1rem;
    color: red;
    text-align: center;
  }
}
