.circular-progress {
  color: white;
  // min-height: 80px !important;
}

:global {
  .ant-progress-text {
    color: white !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 800 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    @media only screen and (max-width: 720px) {
      font-size: 12px !important;
    }
  }
}
