.carousel-skills {
  display: flex;
  flex-direction: column;

  &__container-blu {
    color: #fff;
    line-height: 16px;
    padding: 50px;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @media only screen and (max-width: 720px) {
      height: 60vh;
      justify-content: flex-start;
    }
  }

  &__title-icon {
    display: flex;
    gap: 16px;
  }

  &__color-icon {
    font-size: 16px;
    color: white;
  }

  &__space-circle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    gap: 24px;

    @media only screen and (max-width: 900px) {
      overflow-x: scroll;
    }
  }
}

:global {
  .slick-arrow slick-next {
    color: black;
  }
}
